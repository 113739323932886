:root {
  --font-family: 'Kumbh Sans', sans-serif !important;

  --font-size-h1: 24pt;
  --font-size-h2: 20pt;
  --font-size-h3: 16pt;
  --font-size-h4: 14pt;

  --s8l-primary: 29 39 62;
  --s8l-primary-contrast: 255 255 255;
  --s8l-secondary: 14 86 129;
  --s8l-danger: 155 29 32;
  --s8l-page: 255 255 255;
  --s8l-light-grey: 245 245 246;
  --s8l-grey: 139 144 147;
  --s8l-prose: 33 37 41;
  --s8l-success: 35 158 66;
  --s8l-warning: 255 159 10;
}

@import '@fontsource/kumbh-sans/300.css';
@import '@fontsource/kumbh-sans/400.css';
@import '@fontsource/kumbh-sans/700.css';
@import 'swiper/swiper-bundle.min.css';

@import 'variables.scss';
@import 'animation.scss';

// start here
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  * {
    font-family: var(--font-family);
  }
}

// Text Styles
//=============
// todo: remove margin
h1 {
  @apply mb-10 text-3xl;
}

h2 {
  @apply mb-6 text-2xl;
}

h3 {
  @apply mb-4 text-xl;
}

h4 {
  @apply text-lg;
}

// Page Configurations
//=====================

.s8l-container {
  @apply container m-auto flex flex-col justify-between bg-page px-4 sm:px-28 xl:px-72;
}

// Input Elements
//================
.s8l-input-container {
  @apply m-0.5 flex w-full flex-row flex-wrap items-center justify-between gap-4 rounded-full bg-white p-3 px-8 shadow-md;
}

.s8l-input {
  @apply grow border-prose px-4 py-1.5;
}

// Buttons
//=========
.s8l-button {
  @apply flex flex-row items-center justify-center gap-4 rounded-[30px] p-3 sm:px-8;

  // button-color-styles
  &.s8l-b-primary {
    @apply bg-primary text-primary-contrast;
    transition: background-color 0.25s, box-shadow 0.25s;

    &:hover:enabled {
      @apply shadow-xl;
      background-color: scale(#f5f5f6, 40%);
    }

    &:disabled {
      @apply bg-grey;
    }

    &:focus {
      @apply ring ring-slate-900 ring-offset-1;
    }
  }

  &.s8l-b-clear {
    &:focus {
      @apply ring ring-slate-900 ring-offset-1;
    }
    &:hover:enabled {
      @apply shadow-xl;
    }
    &:disabled {
      @apply text-grey;
    }
  }

  &.s8l-b-icon-only {
    @apply aspect-square p-3;
  }

  &.s8l-b-sm {
    @apply p-4;
  }

  &.s8l-b-header {
    @apply aspect-square bg-white p-3 shadow-xl;
  }
}

// Scrollbar
//===========
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

// Header Elements
//=================
.header-button-container {
  > * {
    @apply pointer-events-auto mx-1;
  }
}

select {
  @apply appearance-none rounded-3xl border-0 bg-transparent py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-gray-600;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

.glossary-link {
  text-decoration: underline;
  text-decoration-color: lighten(#262626, 40%);
  color: var(--font-color);
}

.glossary-popper {
  display: inline-block;
  background: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px #000000;
  box-shadow: 0px 0px 5px 0px #000000;
  padding: 10px;
  max-width: 500px;
  &[x-placement^='bottom'] {
    margin-top: 15px;
  }
  &[x-placement^='top'] {
    margin-bottom: 15px;
  }
}

.app-loading {
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99999999;
  position: absolute;
  top: 0;
  left: 0;

  font-size: 1em;

  transition: all 1s;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;

    .container {
      display: none;
    }
  }

  .container {
    margin: 40px auto auto auto;

    width: 120px;
    height: 120px;

    text-align: center;

    .loader {
      border: 16px solid #f3f3f3;
      border-top: 16px solid;
      @apply border-primary;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
      margin-bottom: 12px;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .refresh {
      margin-top: 12px;

      color: #fff;
      @apply bg-primary;
      padding: 10px;
      border-radius: 7px;

      animation: show 0s 5s forwards;
      visibility: hidden;
    }

    @keyframes show {
      to {
        visibility: visible;
      }
    }
  }
}

// on mobile devices, 100vh includes the url bar and the bottom controls
// (allthough they are not present when running as a pwa...)
// this class forces the element to the _actual_ viewport size
.h-screen-fix {
  height: 100vh;
  max-height: -webkit-fill-available;
}

.relative-positioning {
  position: unset !important;

  &:first-child {
    @apply md:relative;
  }
}

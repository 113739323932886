@import 'variables.scss';

@keyframes animation-pulse {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.15);
  }

  50% {
    transform: scale(1.15);
  }

  90% {
    transform: scale(1);
  }
}
